import styles from './Navbar.module.scss';
import { UserHas } from '../../Auth0Provider';
import TextLink from '@mablemarket/common-react-lib/build/components/TextLink';

const Navbar = () => {
  const items = [
    { title: '💰 Orders', url: '/orders' },
    { title: '👩‍🌾 Brands', url: '/sellers', nonAdminsAllowed: true },
    { title: '🐈 Categories', url: '/categories' },
    { title: '🍫 Products', url: '/products' },
    { title: '👩‍💻 Manual tasks', url: '/manual-tasks-summary', nonAdminsAllowed: true },
    { title: '🆓 Sample requests', url: '/sample-requests' },
    { title: '🔎 Search', url: '/search' },
    { title: '💳 Pay tools', url: '/pay-tools' },
    { title: '💾 Data utils', url: '/data-utils', nonAdminsAllowed: false },
    { title: '🏷️ Pricing utils', url: '/pricing-utils' },
    { title: '📋️ Pricing tiers', url: '/pricing-tiers' },
    { title: '🫰 Partner price review', url: '/partner-price-review' },
    { title: '💹 Pricing Reports', url: '/pricing-reports' },
    { title: '📈 Reports', url: '/reports' },
    { title: '🖼 Preview tools', url: '/preview-tools' },
    { title: '🗓 Jobs', url: '/jobs' },
    { title: '⛳️ Account Flags', url: '/account-flags' },
    { title: '📧 Email review', url: '/email-review' },
    { title: '🏠 Homepage tools', url: '/homepage-tools' },
    { title: '💬 Messaging tools', url: '/messaging-tools' },
  ];
  return (
    <nav className={styles.navbar}>
      <ul className={styles.navbarList}>
        {items.map(item => (
          <UserHas permission={item.nonAdminsAllowed ? 'dataworker' : 'admin'} key={`${item.title}-${item.url}`}>
            <li>
              <TextLink
                exact
                activeProps={{ className: styles.navbarLinkActive }}
                href={item.url}
                className={styles.navbarLink}
                textBoxTrim={false}
                minWidth='100%'
                fontSize='large'
                paddingY='space-1.25'
              >
                {item.title}
              </TextLink>
            </li>
          </UserHas>
        ))}
      </ul>
    </nav>
  );
}

export default Navbar;
