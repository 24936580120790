export const mableAddress = {
  organization: 'Mable Wholesale, Inc.',
  line1: 'P.O. Box 9227',
  locality: 'Boston',
  administrativeAreaLevel1: 'MA',
  postalCode: '02114',
  phone: '(617) 938-3953',
} as const;

// Mable's DUNS number.
// A DUNS number, or Data Universal Numbering System number, is a unique nine-digit number that
// identifies a business entity. Dun & Bradstreet (D&B) assigns and maintains DUNS numbers, and they
// are free to obtain
export const mableDunsNumber = '117335622';
