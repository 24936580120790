var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useContext, useEffect, useMemo, useRef } from 'react';
import { FocusRing, mergeProps, useButton, useHover } from 'react-aria';
import { focusRing } from '../../styles/sprinkles.css';
import { backgrounds } from '../../styles/theme.css';
import { composeRefs } from '../../util';
import childrenAreLiteral from '../../webutils/childrenAreLiteral';
import { cn } from '../../webutils/webutils';
import Box from '../Box';
import Flex from '../Flex';
import Loading from '../Loading';
import Text from '../Text';
import * as styles from './Button.css';
var Button = React.forwardRef(function (props, ref) {
    var isLoading = props.isLoading, givenDisabled = props.disabled, children = props.children, className = props.className, _a = props.variant, variant = _a === void 0 ? 'default' : _a, _b = props.palette, palette = _b === void 0 ? 'orangeCTA' : _b, _c = props.size, size = _c === void 0 ? 'medium' : _c, onPress = props.onPress, onPressStart = props.onPressStart, onPressEnd = props.onPressEnd, onPressChange = props.onPressChange, onPressUp = props.onPressUp, rest = __rest(props, ["isLoading", "disabled", "children", "className", "variant", "palette", "size", "onPress", "onPressStart", "onPressEnd", "onPressChange", "onPressUp"]);
    var buttonRef = useRef(null);
    var disabled = givenDisabled || isLoading;
    var _d = useButton(__assign(__assign({}, rest), { onPress: onPress, onPressStart: onPressStart, onPressEnd: onPressEnd, onPressChange: onPressChange, onPressUp: onPressUp, isDisabled: disabled }), buttonRef), buttonProps = _d.buttonProps, isPressed = _d.isPressed;
    var _e = useHover({
        isDisabled: disabled,
    }), hoverProps = _e.hoverProps, isHovered = _e.isHovered;
    var buttonStyles = useButtonStyles({
        className: className,
        disabled: disabled,
        isHovered: isHovered,
        isLoading: isLoading,
        isPressed: isPressed,
        palette: palette,
        variant: variant,
        size: size,
    });
    // Workaround for
    // https://github.com/facebook/react/issues/9809
    // https://github.com/adobe/react-spectrum/issues/1513
    var hasOnClickHandler = !!props.onClick;
    useEffect(function () {
        var button = buttonRef.current;
        var preventDefault = function (e) {
            var _a, _b;
            // Disabling touchend breaks form submission on mobile
            if (((_a = e.currentTarget) === null || _a === void 0 ? void 0 : _a.getAttribute('type')) === 'submit'
                || ((_b = e.currentTarget) === null || _b === void 0 ? void 0 : _b.getAttribute('type')) === 'reset') {
                return;
            }
            e.preventDefault();
        };
        if (!hasOnClickHandler) {
            button === null || button === void 0 ? void 0 : button.addEventListener('touchend', preventDefault, { passive: false });
        }
        return function () { return button === null || button === void 0 ? void 0 : button.removeEventListener('touchend', preventDefault); };
    }, [hasOnClickHandler]);
    return (<FocusRing focusRingClass={focusRing}>
      <Box as='button' position='relative' type='button' {...mergeProps(rest, hoverProps)} 
    // we want this to override the click based event handlers
    {...buttonProps} ref={composeRefs(buttonRef, ref)} className={buttonStyles.className} fontSize={buttonStyles.fontSize}>
        <Box visibility={isLoading ? 'hidden' : undefined} fontSize={buttonStyles.fontSize}>
          {childrenAreLiteral(children)
            ? <Text foreground='inherit' fontSize={buttonStyles.fontSize}>{children}</Text>
            : children}
        </Box>
        {isLoading && (<Flex position='absolute' top={0} left={0} placeItems='center' minHeight='100%' minWidth='100%'>
            <Loading strokeWidth={8} color={(function () {
                if (variant === 'default') {
                    return backgrounds[palette].lightness === 'dark'
                        ? 'white'
                        : 'grey';
                }
                return 'grey';
            })()} size={30}/>
          </Flex>)}
      </Box>
    </FocusRing>);
});
Button.displayName = 'Button';
export var useButtonStyles = function (props) {
    var className = props.className, disabled = props.disabled, isHovered = props.isHovered, isLoading = props.isLoading, isPressed = props.isPressed, palette = props.palette, variant = props.variant, size = props.size;
    var _a = useBleed(), bleedX = _a.bleedX, bleedY = _a.bleedY;
    return {
        className: cn(styles.base, styles.variants[variant].base, className, styles.variants[variant][palette].base, styles.variants[variant].size[size], !disabled && isHovered && styles.variants[variant][palette].isHovered, !disabled && isPressed && styles.variants[variant][palette].isPressed, disabled && !isLoading && styles.variants[variant].disabled, bleedX && styles.variants[variant].bleed[size].x, bleedY && styles.variants[variant].bleed[size].y),
        fontSize: { medium: 'medium', small: 'small' }[size],
    };
};
var BleedContext = React.createContext({ bleedX: false, bleedY: false });
var useBleed = function () {
    return useContext(BleedContext);
};
/**
 * Buttons have a new technology called `Bleed`. It sets negative margin equal
 * to the padding in each dimension so that the button only takes up the space of
 * its contents. For example, setting bleedY will do the following:
 *
 *                ______________
 *               |             |
 * Some Text     | Button Text |
 *               |_____________|
 *
 * Without any use of Flex/Grid, as the top and bottom padding don't exist for
 * layout purposes.
 *
 * For a better visual representation, refer to the design system I stole this from
 * https://seek-oss.github.io/braid-design-system/components/Button#bleed
 *
 * I would have liked to put the bleed properties directly on `Button`, but we
 * also use `margin` for the flex-gap polyfill, so placing a bleeding button in a
 * gapped Flex row would cause undefined behavior. Instead of relying on devs to
 * enforce using a wrapper element for those cases, `Bleed` exists as both a
 * wrapper element and a context provider to set bleed on the Button.
 *
 */
var Bleed = function (props) {
    var children = props.children, _a = props.bleedX, bleedX = _a === void 0 ? false : _a, _b = props.bleedY, bleedY = _b === void 0 ? false : _b, rest = __rest(props, ["children", "bleedX", "bleedY"]);
    var value = useMemo(function () { return ({ bleedX: bleedX, bleedY: bleedY }); }, [bleedX, bleedY]);
    return (<BleedContext.Provider value={value}>
      <Box display='inline-flex' {...rest}>
        {children}
      </Box>
    </BleedContext.Provider>);
};
export default Object.assign(Button, {
    Bleed: Bleed,
});
