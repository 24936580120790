import React, { HTMLAttributes, useContext, useEffect, useMemo, useRef } from 'react';
import { AriaButtonProps, FocusRing, mergeProps, PressHookProps, useButton, useHover } from 'react-aria';

import { focusRing } from '../../styles/sprinkles.css';
import { backgrounds } from '../../styles/theme.css';
import { composeRefs } from '../../util';
import childrenAreLiteral from '../../webutils/childrenAreLiteral';
import { cn } from '../../webutils/webutils';
import Box, { BoxOwnProps, FlexAndGridChildProps } from '../Box';
import Flex from '../Flex';
import Loading from '../Loading';
import Text from '../Text';

import * as styles from './Button.css';

type Options = {
  variant?: 'default';
  palette?: 'orangeCTA' | 'greyFill' | 'greyDark' | 'blueMas';
} | {
  variant: 'outline';
  palette?: 'orangeCTA' | 'blueMas' | 'greyDark';
} | {
  variant: 'ghost';
  palette?: 'greyDark' | 'black' | 'blueMas';
};

export type ButtonOwnProps =
  FlexAndGridChildProps &
  Pick<BoxOwnProps, 'className'> &
  Omit<PressHookProps, 'isDisabled'> &
  Options &
  {
    isLoading?: boolean;
    size?: 'medium' | 'small';
  };

export type ButtonProps = Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'className'> & ButtonOwnProps;

const Button = React.forwardRef((props: ButtonProps, ref: React.ForwardedRef<HTMLButtonElement>) => {
  const {
    isLoading,
    disabled: givenDisabled,
    children,
    className,
    variant = 'default',
    palette = 'orangeCTA',
    size = 'medium',
    onPress,
    onPressStart,
    onPressEnd,
    onPressChange,
    onPressUp,
    ...rest
  } = props;
  const buttonRef = useRef<HTMLButtonElement>(null);
  const disabled = givenDisabled || isLoading;
  const {
    buttonProps,
    isPressed,
  } = useButton({
    // Need to cast because react aria expects event handlers for HTMLElement,
    // which conflict with ours for HTMLButtonElement. The types are compatible,
    // but typescript isn't smart enough to know that.
    ...rest as AriaButtonProps,
    onPress,
    onPressStart,
    onPressEnd,
    onPressChange,
    onPressUp,
    isDisabled: disabled,
  }, buttonRef);
  const {
    hoverProps,
    isHovered,
  } = useHover({
    isDisabled: disabled,
  });

  const buttonStyles = useButtonStyles({
    className,
    disabled,
    isHovered,
    isLoading,
    isPressed,
    palette,
    variant,
    size,
  });

  // Workaround for
  // https://github.com/facebook/react/issues/9809
  // https://github.com/adobe/react-spectrum/issues/1513
  const hasOnClickHandler = !!props.onClick;
  useEffect(() => {
    const button = buttonRef.current;
    const preventDefault = (e: TouchEvent) => {
      // Disabling touchend breaks form submission on mobile
      if (
        (e.currentTarget as HTMLElement)?.getAttribute('type') === 'submit'
        || (e.currentTarget as HTMLElement)?.getAttribute('type') === 'reset'
      ) {
        return;
      }
      e.preventDefault();
    };
    if (!hasOnClickHandler) {
      button?.addEventListener('touchend', preventDefault, { passive: false });
    }
    return () => button?.removeEventListener('touchend', preventDefault);
  }, [hasOnClickHandler]);

  return (
    <FocusRing focusRingClass={focusRing}>
      <Box
        as='button'
        position='relative'
        type='button'
        {...mergeProps(rest, hoverProps)}
        // we want this to override the click based event handlers
        {...buttonProps}
        ref={composeRefs(buttonRef, ref)}
        className={buttonStyles.className}
        fontSize={buttonStyles.fontSize}
      >
        <Box visibility={isLoading ? 'hidden' : undefined} fontSize={buttonStyles.fontSize}>
          {childrenAreLiteral(children)
            ? <Text foreground='inherit' fontSize={buttonStyles.fontSize}>{children}</Text>
            : children}
        </Box>
        {isLoading && (
          <Flex
            position='absolute'
            top={0}
            left={0}
            placeItems='center'
            minHeight='100%'
            minWidth='100%'
          >
            <Loading
              strokeWidth={8}
              color={(() => {
                if (variant === 'default') {
                  return backgrounds[palette].lightness === 'dark'
                    ? 'white'
                    : 'grey';
                }
                return 'grey';
              })()}
              size={30}
            />
          </Flex>
        )}
      </Box>
    </FocusRing>
  );
});

Button.displayName = 'Button';

interface UseButtonStylesProps {
  variant: NonNullable<ButtonProps['variant']>;
  palette: NonNullable<ButtonProps['palette']>;
  disabled: boolean | undefined;
  isLoading: boolean | undefined;
  className: BoxOwnProps['className'] | undefined;
  isHovered: boolean;
  isPressed: boolean;
  size: 'medium' | 'small';
}
export const useButtonStyles = (props: UseButtonStylesProps) => {
  const {
    className,
    disabled,
    isHovered,
    isLoading,
    isPressed,
    palette,
    variant,
    size,
  } = props;
  const { bleedX, bleedY } = useBleed();
  return {
    className: cn(
      styles.base,
      styles.variants[variant].base,
      className,
      styles.variants[variant][palette].base,
      styles.variants[variant].size[size],
      !disabled && isHovered && styles.variants[variant][palette].isHovered,
      !disabled && isPressed && styles.variants[variant][palette].isPressed,
      disabled && !isLoading && styles.variants[variant].disabled,
      bleedX && styles.variants[variant].bleed[size].x,
      bleedY && styles.variants[variant].bleed[size].y,
    ),
    fontSize: ({ medium: 'medium', small: 'small' } as const)[size],
  } as const;
};

const BleedContext = React.createContext<{ bleedX: boolean, bleedY: boolean }>({ bleedX: false, bleedY: false });
const useBleed = () => {
  return useContext(BleedContext);
};
export interface BleedProps extends BoxOwnProps, Omit<HTMLAttributes<HTMLDivElement>, 'className'> {
  bleedX?: boolean;
  bleedY?: boolean;
  children?: React.ReactNode;
}
/**
 * Buttons have a new technology called `Bleed`. It sets negative margin equal
 * to the padding in each dimension so that the button only takes up the space of
 * its contents. For example, setting bleedY will do the following:
 *
 *                ______________
 *               |             |
 * Some Text     | Button Text |
 *               |_____________|
 *
 * Without any use of Flex/Grid, as the top and bottom padding don't exist for
 * layout purposes.
 *
 * For a better visual representation, refer to the design system I stole this from
 * https://seek-oss.github.io/braid-design-system/components/Button#bleed
 *
 * I would have liked to put the bleed properties directly on `Button`, but we
 * also use `margin` for the flex-gap polyfill, so placing a bleeding button in a
 * gapped Flex row would cause undefined behavior. Instead of relying on devs to
 * enforce using a wrapper element for those cases, `Bleed` exists as both a
 * wrapper element and a context provider to set bleed on the Button.
 *
 */
const Bleed = (props: BleedProps) => {
  const { children, bleedX = false, bleedY = false, ...rest } = props;
  const value = useMemo(() => ({ bleedX, bleedY }), [bleedX, bleedY]);
  return (
    <BleedContext.Provider value={value}>
      <Box display='inline-flex' {...rest}>
        {children}
      </Box>
    </BleedContext.Provider>
  );
};

export default Object.assign(Button, {
  Bleed,
});
